<template>
  <div class="login" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Connexion</h1>
        <div class="form_div">
          <div class="form_content">

            <h2>
              Connectez-vous et accédez à votre tableau de bord
            </h2>
            <span class="text-danger">Attention, ce compte n'a aucun rapport avec votre espace personnel Audiens. Pour déposer une demande,
              il vous faut en créer un directement sur cette plateforme.
            </span>

            <div>
              <b-form v-on:submit.prevent="login()" class="mt-4">
                <b-form-group id="username-input" label="Votre Email" label-for="username-input">
                  <b-input-group>
                    <b-form-input
                      required
                      v-model="username"
                      type="text" id="username-input"
                      name="username-input" autocomplete="on"
                      @input="$v.username.$touch()"
                      :state="$v.username.$dirty ? !$v.username.$error : null">
                    </b-form-input>
                  </b-input-group>
                </b-form-group>

                <b-form-group id="password-input" label="Votre mot de passe" label-for="password-input">
                  <b-form-input
                    required v-model="password"
                    type="password" id="password-input"
                    name="password-input" autocomplete="on"
                    @input="$v.password.$touch()"
                    :state="$v.password.$dirty ? !$v.password.$error : null">
                  </b-form-input>
                  <div class="form-error"
                       v-if="errorLogin">
                       Veuillez vérifier votre email et mot de passe
                  </div>
                  <small> <router-link to="/settings/reset-password"> Mot de passe oublié ?</router-link> </small>
                </b-form-group>

                <b-button type="submit" block variant="outline-primary" class="mt-3" :disabled="contactInputInvalids"> Se connecter </b-button>
              </b-form>
            </div>

            <hr>

            <div class="row text-center">
              <div class="col-12">
                  <contactComponent />
              </div>
            </div>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import contactComponent from '@/components/contactComponent'
import formTitleComponent from '@/components/formTitleComponent'

export default {
  name: 'Login',
  components: {
    contactComponent,
    formTitleComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      username: '',
      password: '',
      errorLogin: false
    }
  },
  computed: {
    contactInputInvalids: function () {
      this.$v.$touch()
      return this.$v.$invalid
    }
  },
  validations: {
    username: {
      required,
      email
    },
    password: {
      required,
    }
  },

  methods: {
    login: function () {
     var username = this.username.toLowerCase()
     var password = this.password

     this.$store.dispatch('AUTH_REQUEST', { username, password })
      .then(() => {
        this.errorLogin = false
        this.$router.push('/')
      })
      .catch(() => {
        this.errorLogin = true
      })
   },
   logout: function () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    },
  }
}
</script>
